import React, { useContext } from 'react'
import { Text } from 'rebass'
import { ChainId, Currency, currencyEquals, ETHER, Token } from '@uniswap/sdk'
import styled, { ThemeContext } from 'styled-components'

import { SUGGESTED_BASES } from '../../constants'
import { AutoColumn } from '../Column'
import QuestionHelper from '../QuestionHelper'
import { AutoRow } from '../Row'
import CurrencyLogo from '../CurrencyLogo'

const BaseWrapper = styled.div<{ disable?: boolean }>`
  border: 1px solid ${({ theme, disable }) => (disable ? 'transparent' : theme.bg3)};
  border-radius: 10px;
  display: flex;
  padding: 6px;

  align-items: center;
  :hover {
    cursor: ${({ disable }) => !disable && 'pointer'};
    background-color: ${({ theme, disable }) => !disable && theme.bg2};
  }

  background-color: ${({ theme, disable }) => disable && theme.bg3};
  opacity: ${({ disable }) => disable && '0.4'};
`

export default function CommonBases({
  chainId,
  onSelect,
  selectedCurrency
}: {
  chainId?: ChainId
  selectedCurrency?: Currency | null
  onSelect: (currency: Currency) => void
}) {
  const theme = useContext(ThemeContext);
  return (
    <AutoColumn gap="md">
      <AutoRow>
        <Text fontWeight={600} fontSize={18} color={theme.value3}>
          Common bases
        </Text>
        <QuestionHelper text="These tokens are commonly paired with other tokens." />
      </AutoRow>
      <AutoRow style={{
        gap: "8px"
      }}>
        <BaseWrapper
          onClick={() => {
            if (!selectedCurrency || !currencyEquals(selectedCurrency, ETHER)) {
              onSelect(ETHER)
            }
          }}
          disable={selectedCurrency === ETHER}
          style={{
            background: theme.bg10,
            padding: "8px 12px",
            margin: 0,
            display: "flex",
            gap: "8px",
            alignItems: "center",
            borderRadius: "999px"
          }}
        >
          <CurrencyLogo currency={ETHER}  />
          <Text fontWeight={500} fontSize={16} color={theme.value3}>
            ETH
          </Text>
        </BaseWrapper>
        {(chainId ? SUGGESTED_BASES[chainId] : []).map((token: Token) => {
          const selected = selectedCurrency instanceof Token && selectedCurrency.address === token.address
          return (
            <BaseWrapper onClick={() => !selected && onSelect(token)} disable={selected} key={token.address}  style={{
              background: theme.bg10,
              padding: "8px 12px",
              margin: 0,
              display: "flex",
              gap: "8px",
              alignItems: "center",
              borderRadius: "999px"
            }}>
              <CurrencyLogo currency={token}  />
              <Text fontWeight={500} fontSize={16} color={theme.value3}>
                {token.symbol}
              </Text>
            </BaseWrapper>
          )
        })}
      </AutoRow>
    </AutoColumn>
  )
}
