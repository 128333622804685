import React, { useContext } from 'react'
import { Link, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { ThemeContext } from 'styled-components'

// Import SVG components
import { ReactComponent as Logo } from '../../assets/svg/qday_logo.svg'
import { ReactComponent as DashboardIcon } from '../../assets/svg/dashboard.svg'
import { ReactComponent as QDayBridgeIcon } from '../../assets/svg/qday_bridge.svg'
import { ReactComponent as QDaySwapIcon } from '../../assets/svg/qday_swap.svg'
import { ReactComponent as QDayStakingIcon } from '../../assets/svg/qday_staking.svg'
import { ReactComponent as QDayLendIcon } from '../../assets/svg/qday_lend.svg'
// import { ReactComponent as ExternalLinkIcon } from '../../assets/svg/external_link.svg'
// import { ReactComponent as AbelStakingIcon } from '../../assets/svg/abel_staking.svg'
// import { TYPE } from '../../theme'

interface LinkItemProps {
  isActive?: boolean
}

const SidebarWrapper = styled.div`
  width: 256px;
  height: 100vh;
  background: ${({ theme }) => theme.bg1};
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: auto;
  border-right: 1px solid ${({ theme }) => theme.border2};
`

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 4.5rem;
  border-bottom: 1px solid ${({ theme }) => theme.border2};
  svg {
    height: 46px;
    width: auto;
  }
`

const NavWrapper = styled.nav`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px;
`

const LinkItem = styled.div<LinkItemProps>`
  width: 224px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  height: 3.5rem;
  padding: 0 1rem 0 3.5rem;
  position: relative;
  transition: all 0.2s ease;
  border-radius: 8px;
  margin-bottom: 16px;
  color: ${({ theme }) => theme.text2};

  a {
    text-decoration: none;
    width: 100%;
    line-height: 16px;
    letter-spacing: 0;
    font-family: Manrope, sans-serif;
    color: inherit;
    font-weight: 500;
  }

  svg {
    flex-shrink: 0;
    transition: fill 0.2s ease;
  }

  &:hover {
    background-color: ${({ theme }) => theme.bg11};

    color: ${({ theme }) => theme.white};

    a {
      color: ${({ theme }) => theme.white};
    }
  }

  ${({ isActive, theme }) =>
    isActive &&
    `
        background-color: ${theme.primary4};
        color:  ${theme.primary1};
        
        &:hover {
          color:  ${theme.primary1};

          a {
            color: ${theme.primary1};
          } 
        }
    `}
`
const QDayFinanceUrl = 'https://devnet-finance.qday.ninja/en'
const QDayExplorerUrl = 'https://devnet-explorer.qday.ninja'
const QDayCommunityUrl = 'https://community.pqabelian.io'

export const NAV_ITEMS = [
  { path:QDayFinanceUrl, Icon: DashboardIcon, text: 'Dashboard', isExternal: true },  
  {
    path: `${QDayFinanceUrl}/dapps/faucet`,
    Icon: DashboardIcon,
    text: 'Faucet',
    isExternal: true
  },{
    path: QDayExplorerUrl,
    Icon: DashboardIcon,
    text: 'Explorer',
    isExternal: true
  },
  {
    path: `${QDayFinanceUrl}/en/dapps/bridge`,
    Icon: QDayBridgeIcon,
    text: 'Bridge',
    isExternal: true
  },
  { path: '/swap', Icon: QDaySwapIcon, text: 'Swap', isExternal: false },
  { path:  `${QDayFinanceUrl}/dapps/staking` , Icon: QDayStakingIcon, text: 'Staking', isExternal: true },
  { path: `${QDayFinanceUrl}/dapps/lending` , Icon: QDayLendIcon, text: 'Lending', isExternal: true },
  { path: QDayCommunityUrl , Icon: DashboardIcon, text: 'Community', isExternal: true },

] as const

const Sidebar: React.FC = () => {
  const theme = useContext(ThemeContext)
  const location = useLocation()

  const isActive = (to: string): boolean | undefined => {
    return location.pathname === to || (to === '/swap' && location.pathname === '/pool')
  }

  return (
    <SidebarWrapper>
      <LogoWrapper>
        <Logo fill={theme.text1} />
      </LogoWrapper>

      <NavWrapper>
        {NAV_ITEMS.map(item => (
          <LinkItem isActive={isActive(item.path)} key={item.path}>
            {<item.Icon />}
            {item.isExternal ? (
              <a href={item.path} target="_blank" rel="noopener noreferrer">
                {item.text}
              </a>
            ) : (
              <Link to={item.path}>{item.text}</Link>
            )}
          </LinkItem>
        ))}
      </NavWrapper>
    </SidebarWrapper>
  )
}

export default Sidebar
