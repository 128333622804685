import { ChainId } from '@uniswap/sdk'
import React from 'react'
import { isMobile } from 'react-device-detect'
import { Text } from 'rebass'
import styled from 'styled-components'
import { useActiveWeb3React } from '../../hooks'
import { useETHBalances } from '../../state/wallet/hooks'
import { YellowCard } from '../Card'
import Web3Status from '../Web3Status'
import NavbarMenuToggle from '../NavbarMenuToggle'
import { createPortal } from 'react-dom'
import { NAV_ITEMS } from '../Sidebar'
import { ReactComponent as ExternalLinkIcon } from '../../assets/svg/external_link.svg'
const HeaderFrame = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-family: 'Manrope';
  padding: 0 1rem;
  height: 4.5rem;
  box-sizing: border-box;
  flex-shrink: 0;
  border-bottom: 1px solid ${({ theme }) => theme.border2};
  background: ${({ theme }) => theme.bg1};
  position: relative;
  z-index: 31;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    height: 4rem;
    width: 100vw;
    position: fixed;
    padding: 0 16px;
    box-sizing: border-box;
    top: 0;
  `};
`

const HeaderElement = styled.div`
  display: flex;
  align-items: center;
`

const AccountElement = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme, active }) => (!active ? theme.bg1 : theme.bg1)};
  border: 1px solid ${({ theme }) => theme.border4};
  white-space: nowrap;
  width: 100%;
  border-radius: 9999px;
  :focus {
    border: 1px solid blue;
  }
`

const TestnetWrapper = styled.div`
  white-space: nowrap;
  width: fit-content;
  margin-left: 10px;
  pointer-events: auto;
`

const NetworkCard = styled(YellowCard)`
  width: fit-content;
  margin-right: 10px;
  border-radius: 6px;
  padding: 12px 20px;
  color: ${({ theme }) => theme.textOrange};
  background-color: ${({ theme }) => theme.bg8};
  font-size: 16px;
  font-weight: 400;
`

const BalanceText = styled(Text)`
  font-size: 16px;
  font-weight: 400;
  padding: 12px;
  color: ${({ theme }) => theme.value2};
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
  `};
`

const NETWORK_LABELS: { [chainId in ChainId]: string | null } = {
  [ChainId.MAINNET]: null,
  [ChainId.RINKEBY]: 'Rinkeby',
  [ChainId.ROPSTEN]: 'Ropsten',
  [ChainId.GÖRLI]: 'Görli',
  [ChainId.KOVAN]: 'Kovan',
  [ChainId.QDAY]: 'QDay Network'
}

const NavMenu = styled.div<{ isOpen: boolean }>`
  position: fixed;
  width: 100vw;
  height: 0;
  top: 4rem;
  left: 0;
  right: 0;
  background-color: #000000b3;
  backdrop-filter: blur(24px) saturate(1.5);
  z-index: 30;
  overflow: hidden;
  transition: height 0.3s ease-out;
  display: none;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: block;
  `};
  ${({ isOpen }) =>
    isOpen &&
    `
    height: calc(100vh - 4rem);
  `}
`

const NavMenuContent = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0.5rem 1.5rem 0 1.5rem;
`

const NavMenuItem = styled.li`
  border-bottom: 1px solid ${({ theme }) => theme.border2};
  height: 60px;
  padding: 0 1rem;
  box-sizing: content-box;
  display: flex;
  align-items: center;

  a {
    font-family: 'Manrope';
    line-height: 61px;
    font-size: 1.125rem;
    color: ${({ theme }) => theme.text8};
    text-decoration: none;
    display: block;
    width: 100%;
    display: flex;
    align-items: center;
    svg {
      margin-left: 4px;
    }
  }
`

const NavMenuComponent = ({ isOpen }: { isOpen: boolean }) => {
  return createPortal(
    <NavMenu isOpen={isOpen} data-open={isOpen}>
      <NavMenuContent>
        {NAV_ITEMS.map((el, index) => (
          <NavMenuItem key={el.path} style={{ '--item-index': index } as React.CSSProperties}>
            <a
              href={el.path}
              target={el.isExternal ? '_blank' : '_self'}
              rel={el.isExternal ? 'noopener noreferrer' : ''}
            >
              {el.text}
              {el.isExternal && <ExternalLinkIcon />}
            </a>

          </NavMenuItem>
        ))}
      </NavMenuContent>
    </NavMenu>,
    document.body
  )
}

export default function Header() {
  const { account, chainId } = useActiveWeb3React()
  const [isSidebarOpen, setIsSidebarOpen] = React.useState(false)
  const userEthBalance = useETHBalances(account ? [account] : [])?.[account ?? '']

  const handleToggleSidebar = (boolean: boolean) => {
    setIsSidebarOpen(boolean)
    document.body.style.overflow = boolean ? 'hidden' : ''
  }

  React.useEffect(() => {
    return () => {
      document.body.style.overflow = ''
    }
  }, [])

  return (
    <HeaderFrame>
      <HeaderElement>
        <NavbarMenuToggle onToggle={handleToggleSidebar} />
        <NavMenuComponent isOpen={isSidebarOpen} />
      </HeaderElement>
      <HeaderElement>
        <TestnetWrapper>
          {!isMobile && chainId && NETWORK_LABELS[chainId] && <NetworkCard>{NETWORK_LABELS[chainId]}</NetworkCard>}
        </TestnetWrapper>

        <AccountElement active={!!account} style={{ pointerEvents: 'auto' }}>
          {account && userEthBalance ? (
            <BalanceText style={{ flexShrink: 0 }} pl="0.75rem" pr="0.5rem" fontWeight={500}>
              {userEthBalance?.toSignificant(4)} QDAY
            </BalanceText>
          ) : null}
          <Web3Status />
        </AccountElement>
      </HeaderElement>
    </HeaderFrame>
  )
}
