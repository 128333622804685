import { Currency, Percent, Price } from '@uniswap/sdk'
import React, { useContext } from 'react'
import { Text } from 'rebass'
import { ThemeContext } from 'styled-components'
import { AutoColumn } from '../../components/Column'
import { AutoRow } from '../../components/Row'
import { ONE_BIPS } from '../../constants'
import { Field } from '../../state/mint/actions'
import { TYPE } from '../../theme'

export function PoolPriceBar({
  currencies,
  noLiquidity,
  poolTokenPercentage,
  price
}: {
  currencies: { [field in Field]?: Currency }
  noLiquidity?: boolean
  poolTokenPercentage?: Percent
  price?: Price
}) {
  const theme = useContext(ThemeContext)
  return (
    <AutoColumn gap="md" >
      <AutoRow  style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "12px"
        }}
      >
        <AutoColumn  style={{
          display: "flex",
          flexDirection: "row-reverse",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center"
        }}>
          <TYPE.black style={{
            fontSize: 14,
            fontWeight: 500,
            color: theme.value3
          }}>{price?.toSignificant(6) ?? '-'}</TYPE.black>
          <Text fontWeight={400} fontSize={14} color={theme.value2}>
            {currencies[Field.CURRENCY_B]?.symbol} per {currencies[Field.CURRENCY_A]?.symbol}
          </Text>
        </AutoColumn>
        <AutoColumn style={{
          display: "flex",
          flexDirection: "row-reverse",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center"
        }}>
          <TYPE.black style={{
            fontSize: 14,
            fontWeight: 500,
            color: theme.value3
          }}>{price?.invert()?.toSignificant(6) ?? '-'}</TYPE.black>
          <Text fontWeight={400} fontSize={14} color={theme.value2}>
            {currencies[Field.CURRENCY_A]?.symbol} per {currencies[Field.CURRENCY_B]?.symbol}
          </Text>
        </AutoColumn>
        <AutoColumn style={{
          display: "flex",
          flexDirection: "row-reverse",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center"
        }}>
          <TYPE.black style={{
            fontSize: 14,
            fontWeight: 500,
            color: theme.value3
          }}>
            {noLiquidity && price
              ? '100'
              : (poolTokenPercentage?.lessThan(ONE_BIPS) ? '<0.01' : poolTokenPercentage?.toFixed(2)) ?? '0'}
            %
          </TYPE.black>
          <Text fontWeight={400} fontSize={14} color={theme.value2}>
            Share of Pool
          </Text>
        </AutoColumn>
      </AutoRow>
    </AutoColumn>
  )
}
