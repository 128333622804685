import React, { ReactNode } from "react";
import styled from "styled-components";

const WrapperComponent = styled.div`
  display: flex
`;

interface WrapperProps {
  children: ReactNode;
  style?: React.CSSProperties
}

const FlexWrapper= ({ children , style}:WrapperProps ) => {
  return <WrapperComponent style={style}>{children}</WrapperComponent>;
};

export default FlexWrapper;
