import React, { useState } from 'react';
import styled from 'styled-components';

const ToggleButton = styled.button`
    position: relative;
    width: 24px;
    height: 24px;
    display: none;
    transition: all 0.2s;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;

    &:hover {
        background: rgba(0, 0, 0, 0.05);
    }

    &:active {
        background: rgba(0, 0, 0, 0.1);
    }

    ${({ theme }) => theme.mediaWidth.upToSmall`
        display: flex;
  `};
`;

const ToggleContainer = styled.div`
    position: relative;
    height: 24px;
    width: 24px;
`;

const Line = styled.span<{ isOpen: boolean }>`
    position: absolute;
    width: 24px;
    height: 1px;
    background-color: #ECEDEF;
    transform-origin: center;
    transition: all 0.2s;
    left: 0;

    &:first-child {
        top: ${props => props.isOpen ? '12px' : '7px'};
        transform: ${props => props.isOpen ? 'rotate(45deg)' : 'rotate(0)'};
    }

    &:last-child {
        top: ${props => props.isOpen ? '12px' : '16px'};
        transform: ${props => props.isOpen ? 'rotate(-45deg)' : 'rotate(0)'};
    }
`;

type NavbarMenuToggleProps = {
    onToggle: (isOpen: boolean) => void;
}
export default function NavbarMenuToggle({ onToggle }: NavbarMenuToggleProps) {
    const [isOpen, setIsOpen] = useState(false);

    const handleClick = () => {
        setIsOpen(!isOpen);
        if (onToggle) {
            onToggle(!isOpen);
        }
    };

    return (
        <ToggleButton onClick={handleClick} aria-label="Toggle menu">
            <ToggleContainer>
                <Line isOpen={isOpen} />
                <Line isOpen={isOpen} />
            </ToggleContainer>
        </ToggleButton>
    );
};
