// the Uniswap Default token list lives here
export const DEFAULT_TOKEN_LIST_URL = window.location.origin + '/tokens_list.json'

export const DEFAULT_LIST_OF_LISTS: string[] = [DEFAULT_TOKEN_LIST_URL]

export const QDAY_DEFAULT_LIST = {
  name: 'QDAY',
  logoURI: 'https://raw.githubusercontent.com/compound-finance/token-list/master/assets/compound-interface.svg',
  keywords: [],
  timestamp: '2021-05-27T20:37:00.000+00:00',
  tokens: [
    {
      chainId: 1001,
      address: '0x93A48BC21B6558ebe4417F36716549e9439a64d4',
      name: 'QDay USD',
      symbol: 'USDQ',
      decimals: 18,
      logoURI: 'https://raw.githubusercontent.com/compound-finance/token-list/master/assets/asset_USDT.svg'
    },
    {
      chainId: 1001,
      address: '0x5571c9263450a1c50586ac91d3B841C71eCe5281',
      name: 'Abelian Post-Quantum USD',
      symbol: 'pqUSD',
      decimals: 18,
      logoURI: 'https://raw.githubusercontent.com/compound-finance/token-list/master/assets/asset_USDC.svg'
    }
  ],
  version: {
    major: 2,
    minor: 0,
    patch: 0
  }
}
